import React from 'react'
import { sagaModal } from 'redux-saga-modal'

import { ModalWrapper, ModalBody, ModalFooter } from '~/containers/ModalWrapper'

import MODAL_TYPES from '~/data/modal-types'
import { useAutofocusElement } from '~/utils/hooks'

const Modal = ({
	isOpen,
	hide,
	data={},
}) => {
	const autofocusDOMRef = useAutofocusElement()
	return (
		<ModalWrapper
			isOpen={isOpen}
			hide={hide}
			data={data}
		>
			<ModalBody>
				<p>{data.text}</p>
			</ModalBody>
			<ModalFooter>
				<button type="button" className="default" onClick={hide} ref={autofocusDOMRef}>Def</button>
				<button type="button" className="warning" onClick={hide}>War</button>
				<button type="button" className="danger" onClick={hide}>Dan</button>
				<button type="button" className="success" onClick={hide}>Suc</button>
				<button type="button" className="info" onClick={hide}>Inf</button>
			</ModalFooter>
		</ModalWrapper>
	)
}

export default sagaModal({
	name: MODAL_TYPES.DEMO,
	destroyOnHide: false,
	keepComponentOnHide: true,
})(Modal)
