import { POST_IT, TASK } from '~/actions/types'
import { VisibilityFilters } from '~/actions/tasks'

const initialState = {
	editing: '',
	filter: VisibilityFilters.SHOW_ALL,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case POST_IT.VIEW_TASKS: {
			return initialState
		}

		case TASK.EDIT: {
			const { uid } = action
			return {
				...state,
				editing: uid,
			}
		}

		case TASK.SAVE_EDIT: {
			return {
				...state,
				editing: null,
			}
		}

		case TASK.CANCEL_EDIT: {
			return {
				...state,
				editing: null,
			}
		}

		case TASK.SET_VISIBILITY_FILTER: {
			const { filter } = action
			return {
				...state,
				filter,
			}
		}

		default:
			return state
	}
}
