import { createModal } from 'redux-saga-modal'
import { race, take } from 'redux-saga/effects'

import MODAL_TYPES from '~/data/modal-types'
import { POST_IT } from '../actions/types'

export function* confirmModal(initProps) {
	const modal = createModal(MODAL_TYPES.CONFIRM)

	yield modal.show({ data: {
		type: 'confirm',
		severity: 'default',
		...initProps,
	}})

	const winner = yield race({
		submit: modal.takeSubmit(),
		hide: modal.takeHide(),
	})

	if (winner.submit) {
		yield modal.hide()
		return modal
	}
	yield modal.destroy()
}

export function* alertModal(initProps) {
	const modal = createModal(MODAL_TYPES.ALERT)

	yield modal.show({ data: {
		type: 'alert',
		severity: 'default',
		...initProps,
	}})

	yield modal.takeHide()

	yield modal.destroy()
}

export function* promptModal(initProps) {
	const modal = createModal(MODAL_TYPES.PROMPT)

	yield modal.show({ data: {
		type: 'prompt',
		severity: 'default',
		...initProps,
	}})

	const winner = yield race({
		submit: modal.takeSubmit(),
		hide: modal.takeHide(),
	})

	if (winner.submit) {
		yield modal.hide()
		return {
			modal,
			values: winner.submit.payload,
		}
	}
	yield modal.destroy()
}

export function* demoModal(initProps) {
	const modal = createModal(MODAL_TYPES.DEMO)

	yield modal.show({ data: {
		type: 'demo',
		severity: 'default',
		...initProps,
	}})

	yield modal.takeHide()

	yield modal.destroy()
}

export function* viewTasksModal(initProps) {
	const modal = createModal(MODAL_TYPES.VIEW_TASKS)

	yield modal.show({ data: {
		type: 'view-tasks',
		severity: 'default',
		...initProps,
	}})

	yield race({
		hide: modal.takeHide(),
		deleted: take(POST_IT.DELETE_POST_IT_ACTION)
	})

	yield modal.destroy()
}
