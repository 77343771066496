import React from 'react'
import PropTypes from 'prop-types'

import imgProfile from '~/assets/profile-icon.png'

const Navbar = ({
	onSaveButtonClicked,
	onDownloadButtonClicked,
	onInputFileChanged,
}) => (
	<div className="navbar">
		<p className="title">Post-It</p>
		<div className="actions">
			<button onClick={onSaveButtonClicked}><i className="icon-save" aria-hidden="true"></i></button>
			<button onClick={onDownloadButtonClicked}><i className="icon-download" aria-hidden="true"></i></button>
			<div className="upload-btn-wrapper">
				<input type="file" accept="application/json" onChange={onInputFileChanged} />
				<button><i className="icon-upload" aria-hidden="true"></i></button>
			</div>
		</div>
		<div className="profile">
			<img src={imgProfile} alt="Profile"/>
		</div>
	</div>
)

Navbar.propTypes = {
	onSaveButtonClicked: PropTypes.func.isRequired,
	onDownloadButtonClicked: PropTypes.func.isRequired,
	onInputFileChanged: PropTypes.func.isRequired,
}

export default Navbar
