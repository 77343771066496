import { TASK } from '~/actions/types'

import { move } from '~/utils/array'

export default (state=[], action) => {
	switch (action.type) {
		case TASK.ADD: {
			const { uid, text } = action
			return [
				...state,
				{
					uid,
					text,
					completed: false,
				}
			]
		}

		case TASK.TOGGLE_ALL_ACTION: {
			const { checked } = action
			return state.map(task => ({ ...task, completed: checked }))
		}

		case TASK.TOGGLE: {
			const { uid } = action
			return state.map(task =>
				task.uid === uid ? { ...task, completed: !task.completed } : task
			)
		}

		case TASK.DESTROY: {
			const { uid } = action
			return state.filter(task => task.uid !== uid)
		}
		
		case TASK.CLEAR_COMPLETED: {
			return state.filter(task => !task.completed)
		}

		case TASK.SAVE_EDIT: {
			const { uid, text } = action
			return state.map(task =>
				task.uid === uid ? { ...task, text } : task
			)
		}

		case TASK.REORDER_TASK: {
			const { from, to } = action
			return move(state, from, to)
		}

		default:
			return state
	}
}
