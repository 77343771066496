import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import Modal from 'react-modal'

import createRootReducer from './reducers'
import './i18n'
import App from './App'
import * as serviceWorker from './serviceWorker'
import appSagas from './sagas'
import './styles/index.scss'

const sagaMiddleware = createSagaMiddleware()
const store = createStore(createRootReducer(), composeWithDevTools(
	applyMiddleware(sagaMiddleware),
))
sagaMiddleware.run(appSagas)

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
)

Modal.setAppElement('#root')

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
