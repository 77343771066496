import { POST_IT, TASK } from '~/actions/types'

const initialState = {
	isDirty: false,
}

export default (state = initialState, action) => {
	switch (action.type) {

		case POST_IT.CHANGE_COLOR:
		case POST_IT.CREATION_REQUEST:
		case POST_IT.REORDER_POST_IT:
		case POST_IT.DELETE_POST_IT_ACTION:
		case POST_IT.SAVE_EDIT_TITLE:
		case TASK.ADD:
		case TASK.TOGGLE_ALL_ACTION:
		case TASK.TOGGLE:
		case TASK.DESTROY:
		case TASK.CLEAR_COMPLETED:
		case TASK.SAVE_EDIT:
		case TASK.REORDER_TASK: {
			return { ...state, isDirty: true }
		}

		case POST_IT.UPLOAD_LIST_CONTENT:
		case POST_IT.SAVE_LIST: {
			return { ...state, isDirty: false} 
		}

		default:
			return state
	}
}
