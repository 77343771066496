import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TaskListHeaderComponent from '~/components/TaskListHeader'

import { ENTER_KEY } from '~/data'
import { addTask } from '~/actions/tasks'

class TaskListHeader extends React.PureComponent
{
	state = {
		newTask: '',
	}

	handleNewTaskChange = (event) => this.setState({ newTask: event.target.value })

	handleNewTaskKeyDown = (event) => {
		if (event.keyCode !== ENTER_KEY) {
			return
		}

		event.preventDefault()

		const val = this.state.newTask.trim()

		const { addTask, postItUid } = this.props

		if (val) {
			addTask(postItUid, val)
			this.setState({ newTask: '' })
		}
	}

	render() {
		return (
			<TaskListHeaderComponent
				newTask={this.state.newTask}
				handleNewTaskKeyDown={this.handleNewTaskKeyDown}
				handleChange={this.handleNewTaskChange}
			/>
		)
	}
}

TaskListHeader.propTypes = {
	postItUid: PropTypes.string.isRequired,
}

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		addTask,
	}, dispatch)
)

export default connect(null, mapDispatchToProps)(TaskListHeader)
