import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import history from '~/utils/history'
import Notifications from '~/containers/Notifications'
import Navbar from '~/containers/Navbar'
import NotFound from '~/routes/NotFound'

import IndexRoute from '~/routes/Index'
import MODALS from '~/components/modals'
import WarnOnClosingWindow from '~/containers/WarnOnClosingWindow'

const App = ({ notifications }) => {
	return (
		<>
			<Router history={history}>
				<Navbar />
				<div className="container">
					<Switch>
						<Route exact path="/" component={IndexRoute} />
						<Route component={NotFound} />
					</Switch>
				</div>
			</Router>
			<Notifications notifications={notifications} />
			{ MODALS.map((E, i) => <E key={i} />) }
			<WarnOnClosingWindow />
		</>
	)
}

export default connect(
	({ notifications }) => ({
		notifications,
	}),
)(App)
