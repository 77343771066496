export const NOTIFICATIONS = {
	SHOW: 'NOTIFICATIONS_SHOW',
	HIDE: 'NOTIFICATIONS_HIDE',
	REMOVE_ALL: 'NOTIFICATIONS_REMOVE_ALL',
}

export const POST_IT = {
	LIST_REQUEST: 'POST_IT:LIST_REQUEST',
	LIST_SUCCESS: 'POST_IT:LIST_SUCCESS',
	LIST_FAILURE: 'POST_IT:LIST_FAILURE',
	CREATION_REQUEST: 'POST_IT:CREATION_REQUEST',
	VIEW_TASKS: 'POST_IT:VIEW_TASKS',
	REORDER_POST_IT: 'POST_IT:REORDER_POST_IT',
	SAVE_LIST: 'POST_IT:SAVE_LIST',
	DOWNLOAD_LIST: 'POST_IT:DOWNLOAD_LIST',
	UPLOAD_LIST_CONTENT: 'POST_IT:UPLOAD_LIST_CONTENT',
	UPLOAD_LIST_ERROR: 'POST_IT:UPLOAD_LIST_ERROR',
	EDIT_TITLE: 'POST_IT:EDIT_TITLE',
	SAVE_EDIT_TITLE: 'POST_IT:SAVE_EDIT_TITLE',
	CANCEL_EDIT_TITLE: 'POST_IT:CANCEL_EDIT_TITLE',
	DELETE_POST_IT: 'POST_IT:DELETE_POST_IT',
	DELETE_POST_IT_ACTION: 'POST_IT:DELETE_POST_IT_ACTION',
	CHANGE_COLOR: 'POST_IT:CHANGE_COLOR',
}

export const TASK = {
	ADD: 'TASK:TASK',
	TOGGLE_ALL: 'TASK:TOGGLE_ALL',
	TOGGLE_ALL_ACTION: 'TASK:TOGGLE_ALL_ACTION',
	TOGGLE: 'TASK:TOGGLE',
	DESTROY: 'TASK:DESTROY',
	CLEAR_COMPLETED: 'TASK:CLEAR_COMPLETED',
	EDIT: 'TASK:EDIT',
	SAVE_EDIT: 'TASK:SAVE_EDIT',
	CANCEL_EDIT: 'TASK:CANCEL_EDIT',
	SET_VISIBILITY_FILTER: 'TASK:SET_VISIBILITY_FILTER',
	REORDER_TASK: 'TASK:REORDER_TASK',
}
