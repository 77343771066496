import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TaskListFooterComponent from '~/components/TaskListFooter'

import {
	clearCompletedTasks,
	setVisibilityFilter,
} from '~/actions/tasks'

class TaskListFooter extends React.PureComponent
{
	clearCompleted = () => {
		const { clearCompletedTasks, postItUid } = this.props
		clearCompletedTasks(postItUid)
	}

	changeVisibilityFilter = (filter) => {
		const { setVisibilityFilter } = this.props
		setVisibilityFilter(filter)
	}

	render() {
		const {
			count,
			completedCount,
			filter,
		} = this.props
		return (
			<TaskListFooterComponent
				count={count}
				completedCount={completedCount}
				onClearCompleted={this.clearCompleted}
				filter={filter}
				changeVisibilityFilter={this.changeVisibilityFilter}
			/>
		)
	}
}

TaskListFooter.propTypes = {
	postItUid: PropTypes.string.isRequired,
	count: PropTypes.number.isRequired,
	completedCount: PropTypes.number.isRequired,
}

const mapStateToProps = (state) => ({
	filter: state.tasks.filter,
})

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		clearCompletedTasks,
		setVisibilityFilter,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(TaskListFooter)
