import React from 'react'
import { connect } from 'react-redux'

import { warning } from '~/actions/notifications'

class WarnOnClosingWindow extends React.Component
{
	componentDidUpdate = () => {
		const { isDirty } = this.props
		this._promptUnsavedChange(isDirty, 'Are you sure you want to leave with unsaved change?')
	}

	componentWillUnmount() {
		window.onbeforeunload = null
	}

	_promptUnsavedChange(isDirty=false, leaveMessage) {
		const { dispatch } = this.props

		window.onbeforeunload = isDirty ? ((e) => {
			e = e || window.event
			if (e) { // For IE and Firefox
				e.returnValue = leaveMessage
			}

			dispatch(warning({
				title: 'Warning',
				message: leaveMessage,
			}))

			// For Safari
			return leaveMessage
		}) : undefined
	}

	render() {
		return null
	}
}

const mapStateToProps = ({ app }) => ({ isDirty: app.isDirty })

export default connect(mapStateToProps)(WarnOnClosingWindow)
