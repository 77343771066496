import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { deletePostIt } from '~/actions/post-it'

class PostItDeleteButton extends React.PureComponent
{
	onDeleteButtonClicked = () => {
		const { deletePostIt, postItUid } = this.props
		deletePostIt(postItUid)
	}

	render() {
		return (
			<button type="button" className="icon-button delete" aria-label="Delete Post-It" onClick={this.onDeleteButtonClicked}>
				<i className="icon-bin" aria-hidden="true"></i>
			</button>
		)
	}
}

PostItDeleteButton.propTypes = {
	postItUid: PropTypes.string.isRequired,
}

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		deletePostIt,
	}, dispatch)
)

export default connect(null, mapDispatchToProps)(PostItDeleteButton)
