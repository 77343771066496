import React from 'react'
import { sagaModal } from 'redux-saga-modal'
import { withTranslation } from 'react-i18next'

import { ModalWrapper, ModalBody, ModalFooter } from '~/containers/ModalWrapper'

import MODAL_TYPES from '~/data/modal-types'
import { useAutofocusElement } from '~/utils/hooks'

const Modal = ({
	t,
	isOpen,
	hide,
	data={},
}) => {
	const autofocusDOMRef = useAutofocusElement()
	return (
		<ModalWrapper
			isOpen={isOpen}
			hide={hide}
			data={data}
		>
			<ModalBody>
				<p>{data.text}</p>
			</ModalBody>
			<ModalFooter>
				<button type="button" className="default" onClick={hide} ref={autofocusDOMRef}>{t('modal.actions.close')}</button>
			</ModalFooter>
		</ModalWrapper>
	)
}

export default withTranslation()(
	sagaModal({
		name: MODAL_TYPES.ALERT,
		destroyOnHide: false,
		keepComponentOnHide: true,
	})(Modal)
)
