import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const TaskItem = ({
	task: { completed, text },
	editFieldRef,
	editing,
	onToggle,
	handleEdit,
	onDestroy,
	editText,
	handleSubmit,
	handleChange,
	handleKeyDown,
}) => (
	<li className={classNames('task-list-item', {
		completed,
		editing,
	})}>
		<div className="view">
			<input
				className="toggle"
				type="checkbox"
				checked={completed}
				onChange={onToggle}
			/>
			<label onDoubleClick={handleEdit}>
				{text}
			</label>
			<button className="destroy" onClick={onDestroy}></button>
		</div>
		<input
			type="text"
			ref={editFieldRef}
			className="edit"
			value={editText}
			onBlur={handleSubmit}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
		/>
	</li>
)

TaskItem.propTypes = {
	task: PropTypes.shape({
		completed: PropTypes.bool.isRequired,
		text: PropTypes.string.isRequired,
	}).isRequired,
	editFieldRef: PropTypes.object.isRequired,
	editing: PropTypes.bool.isRequired,
	onToggle: PropTypes.func.isRequired,
	handleEdit: PropTypes.func.isRequired,
	onDestroy: PropTypes.func.isRequired,
	editText: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleKeyDown: PropTypes.func.isRequired,
}

export default TaskItem
