import { NOTIFICATIONS } from './types'

export const show = (opts = {}, level = 'success') => ({
	type: NOTIFICATIONS.SHOW,
	...opts,
	uid: opts.uid || Date.now(),
	level,
	position: 'br',
})

export const success = (opts) => show(opts, 'success')

export const error = (opts) => show(opts, 'error')

export const warning = (opts) => show(opts, 'warning')

export const info = (opts) => show(opts, 'info')

export const hide = (uid) => ({
	type: NOTIFICATIONS.HIDE,
	uid,
})

export const removeAll = () => ({
	type: NOTIFICATIONS.REMOVE_ALL,
})
