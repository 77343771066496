import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Dragula from 'react-dragula'

import PostItCard from '~/components/PostItCard'
import NewPostItButton from '~/components/NewPostItButton'

import {
	listRequest,
	creationRequest,
	viewTasks,
	reorderPostIt,
} from '~/actions/post-it'

class Index extends React.Component
{
	componentDidMount() {
		const { listRequest } = this.props
		listRequest()
	}

	onNewPostItButtonClicked = (e) => {
		e.preventDefault()
		const { creationRequest } = this.props
		creationRequest()
	}
	
	onCardClicked = (uid) => {
		const { viewTasks } = this.props
		viewTasks(uid)
	}

	dragulaDecorator = (componentBackingInstance) => {
		const { reorderPostIt } = this.props
		if (componentBackingInstance) {
			const options = {}
			const drake = Dragula([componentBackingInstance], options)
			let dragIndex = -1
			drake.on('drag', (el) => {
				el.classList.add('no-pin')
				dragIndex = Array.from(el.parentNode.children).indexOf(el)
			})
			drake.on('cancel', (el) => {
				el.classList.remove('no-pin')
			})
			drake.on('drop', (el) => {
				el.classList.remove('no-pin')
				const dropIndex = Array.from(el.parentNode.children).indexOf(el)
				if (dragIndex > -1 && dropIndex > -1) {
					reorderPostIt(dragIndex, dropIndex)
				}
			})
		}
	}

	render() {
		const { list, loading } = this.props
		return (
			<>
				{ loading && <p>Loading...</p> }

				{ list.length ? (
					<ul className="cards-container" ref={this.dragulaDecorator}>
						{ list.map(e => (
							<PostItCard
								key={e.uid}
								postIt={e}
								onCardClicked={this.onCardClicked}
							/>
						))}
					</ul>
				) : (
					<p>No Post-It found.</p>
				)}

				<NewPostItButton onClicked={this.onNewPostItButtonClicked} />
			</>
		)
	}
}

Index.propTypes = {
	list: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
}

Index.defaultProps = {
	list: [],
	loading: false,
}

const mapStateToProps = ({ postIts }) => {
	return {
		loading: postIts.loading,
		list: postIts.list,
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		listRequest,
		creationRequest,
		viewTasks,
		reorderPostIt,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Index)
