import shortid from 'shortid'

import { POST_IT } from '~/actions/types'

export const listRequest = () => ({
	type: POST_IT.LIST_REQUEST,
})

export const listFailure = (error) => ({
	type: POST_IT.LIST_FAILURE,
	error,
})

export const listSuccess = (list) => ({
	type: POST_IT.LIST_SUCCESS,
	list,
})

export const creationRequest = () => ({
	type: POST_IT.CREATION_REQUEST,
	uid: shortid.generate(),
	title: 'Unnamed',
	color: 'default',
	tasks: [],
})

export const viewTasks = (uid) => ({
	type: POST_IT.VIEW_TASKS,
	uid,
})

export const reorderPostIt = (from, to) => ({
	type: POST_IT.REORDER_POST_IT,
	from,
	to,
})

export const saveList = () => ({
	type: POST_IT.SAVE_LIST,
})

export const downloadList = () => ({
	type: POST_IT.DOWNLOAD_LIST,
})

export const uploadListContent = (text, mimeType) => ({
	type: POST_IT.UPLOAD_LIST_CONTENT,
	text,
	mimeType,
})

export const uploadListError = (reason) => ({
	type: POST_IT.UPLOAD_LIST_ERROR,
	reason,
})

export const editPostItTitle = () => ({
	type: POST_IT.EDIT_TITLE,
})

export const savePostItTitleEdit = (uid, text) => ({
	type: POST_IT.SAVE_EDIT_TITLE,
	uid,
	text,
})

export const cancelPostItTitleEdit = () => ({
	type: POST_IT.CANCEL_EDIT_TITLE,
})

export const deletePostIt = (uid) => ({
	type: POST_IT.DELETE_POST_IT,
	uid,
})

export const deletePostItAction = (uid) => ({
	type: POST_IT.DELETE_POST_IT_ACTION,
	uid,
})

export const changePostItColor = (uid, color) => ({
	type: POST_IT.CHANGE_COLOR,
	uid,
	color,
})
