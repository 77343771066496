import React from 'react'
import classNames from 'classnames'

import { VisibilityFilters } from '~/actions/tasks'

const pluralize = (count, word) => (count !== 1) ? word + 's' : word

const FilterTypeItem = ({
	type,
	current,
	changeVisibilityFilter,
	text,
}) => (
	<li><span className={classNames({selected: current === type})} onClick={() => changeVisibilityFilter(type)}>{text}</span></li>
)

const TaskListFooter = ({
	count,
	completedCount,
	onClearCompleted,
	filter,
	changeVisibilityFilter,
}) => {
	const activeTaskWord = pluralize(count, 'item')
	return (
		<footer>
			<span className="task-count">
				<strong>{count}</strong>
				<span> {activeTaskWord} left</span>
			</span>
			<ul className="filters">
				<FilterTypeItem type={VisibilityFilters.SHOW_ALL} current={filter} text="All" changeVisibilityFilter={changeVisibilityFilter} />
				{' '}
				<FilterTypeItem type={VisibilityFilters.SHOW_ACTIVE} current={filter} text="Active" changeVisibilityFilter={changeVisibilityFilter} />
				{' '}
				<FilterTypeItem type={VisibilityFilters.SHOW_COMPLETED} current={filter} text="Completed" changeVisibilityFilter={changeVisibilityFilter} />
			</ul>
			{ (completedCount > 0) && (
				<button
					className="clear-completed"
					onClick={onClearCompleted}
				>
					Clear completed
				</button>
			)}
		</footer>
	)
}

export default TaskListFooter
