import localforage from 'localforage'

localforage.config({
	driver: localforage.LOCALSTORAGE,
	version: 1.0,
})

export default localforage

export const downloadFileFromText = (options) => {
	const opts = {
		text: '',
		filename: 'export.txt',
		type: 'text/plain',
		timeout: 10000,
		...options,
	}

	const file = new Blob([opts.text], { type: opts.type })

	if (window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveBlob(file, opts.filename)
	}
	else {
		const element = document.createElement('a')
		element.download = opts.filename
		element.href = URL.createObjectURL(file)
		element.dataset.downloadurl = [
			opts.type,
			element.download,
			element.href,
		].join(':')
		element.style.display = 'none'
		document.body.appendChild(element) // Required for this to work in FireFox
		element.click()
		document.body.removeChild(element)
		setTimeout(() => URL.revokeObjectURL(element.href), opts.timeout)
	}
}

export const tryParseJson = (text) => {
	try {
		const o = JSON.parse(text)
		// Handle non-exception-throwing cases:
		// Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
		// but... JSON.parse(null) returns null, and typeof null === "object", 
		// so we must check for that, too. Thankfully, null is falsey, so this suffices:
		if (o && typeof o === 'object') {
			return o
		}
	}
	catch (e) {}
	return undefined
}
