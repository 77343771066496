import englishStrings from 'react-timeago/lib/language-strings/en'
import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'

import localforage from '~/utils/storage'
import { LANGUAGE_STORAGE } from '~/data/storage-keys'

import translationEN from './en/translation.json'
import translationFR from './fr/translation.json'

const enFormatter = buildFormatter(englishStrings)
const frFormatter = buildFormatter(frenchStrings)

// the translations
const resources = {
	en: { translation: translationEN, timeagoFormatter: enFormatter, flag: 'gb' },
	fr: { translation: translationFR, timeagoFormatter: frFormatter, flag: 'fr' },
}

export const defaultLanguage = 'en'

export const saveLocale = (lang) => localforage.setItem(LANGUAGE_STORAGE, lang)

export const loadLocale = (callback) => {
	localforage.getItem(LANGUAGE_STORAGE, (err, lang) => {
		if (err || !(lang in resources)) {
			const systemLanguage = 'en'

			if (systemLanguage && systemLanguage in resources) {
				return callback(systemLanguage)
			}
			else {
				return callback(defaultLanguage)
			}
		}

		return callback(lang)
	})
}

export default resources
