import React from 'react'

import TaskItemComponent from '~/components/TaskItem'

import { ENTER_KEY, ESCAPE_KEY } from '~/data'

class TaskItem extends React.Component
{
	editFieldRef = React.createRef()

	constructor(props) {
		super(props)
		this.state = {
			editText: props.task.text,
		}
	}

	handleSubmit = () => {
		const val = this.state.editText.trim()
		const { onSave, onDestroy } = this.props
		if (val) {
			onSave(val)
			this.setState({ editText: val })
		}
		else {
			onDestroy()
		}
	}

	handleEdit = () => {
		const { onEdit, task: { text } } = this.props
		onEdit()
		this.setState({ editText: text })
	}

	handleKeyDown = (event) => {
		if (event.which === ESCAPE_KEY) {
			const { onCancel, task: { text } } = this.props
			this.setState({ editText: text })
			onCancel(event)
		}
		else if (event.which === ENTER_KEY) {
			this.handleSubmit(event)
		}
	}

	handleChange = (event) => {
		if (this.props.editing) {
			this.setState({ editText: event.target.value })
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (
			nextProps.task !== this.props.task ||
			nextProps.editing !== this.props.editing ||
			nextState.editText !== this.state.editText
		)
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.editing && this.props.editing) {
			const node = this.editFieldRef.current
			node.focus()
			node.setSelectionRange(node.value.length, node.value.length)
		}
	}

	render() {
		const {
			task,
			onToggle,
			onDestroy,
			editing,
		} = this.props
		const { editText } = this.state
		return (
			<TaskItemComponent
				task={task}
				editFieldRef={this.editFieldRef}
				editing={editing}
				onToggle={onToggle}
				handleEdit={this.handleEdit}
				onDestroy={onDestroy}
				editText={editText}
				handleSubmit={this.handleSubmit}
				handleChange={this.handleChange}
				handleKeyDown={this.handleKeyDown}
			/>
		)
	}
}

export default TaskItem
