import React from 'react'

import TaskItem from '~/containers/TaskItem'

const TaskListContent = ({
	toggleAll,
	activeTaskCount,
	tasks,
	editingTask,
	onToggle,
	onDestroy,
	onEdit,
	onSave,
	onCancel,
	dragulaDecorator,
}) => (
	<section>
		<input
			id="toggle-all"
			className="toggle-all"
			type="checkbox"
			onChange={toggleAll}
			checked={activeTaskCount === 0}
		/>
		<label htmlFor="toggle-all"></label>
		<ul className="task-list" ref={dragulaDecorator}>
			{ tasks.map(task => (
				<TaskItem
					key={task.uid}
					task={task}
					onToggle={() => onToggle(task)}
					onDestroy={() => onDestroy(task)}
					onEdit={() => onEdit(task)}
					editing={editingTask === task.uid}
					onSave={(text) => onSave(task, text)}
					onCancel={onCancel}
				/>
			))}
		</ul>
	</section>
)

export default TaskListContent
