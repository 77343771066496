import { POST_IT } from '~/actions/types'
import taskReducer from './post-it-tasks'

import { move } from '~/utils/array'

const initialState = {
	loading: false,
	list: [],
	editingTitle: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case POST_IT.LIST_REQUEST: {
			return {
				...initialState,
				loading: true,
				editingTitle: false,
			}
		}

		case POST_IT.LIST_SUCCESS: {
			const { list } = action
			return {
				...state,
				loading: false,
				list,
				editingTitle: false,
			}
		}

		case POST_IT.LIST_FAILURE: {
			return initialState
		}

		case POST_IT.CREATION_REQUEST: {
			const { type: _, ...rest } = action
			return {
				...state,
				list: [
					...state.list,
					{ ...rest, }
				],
				editingTitle: false,
			}
		}

		case POST_IT.REORDER_POST_IT: {
			const { from, to } = action
			return {
				...state,
				list: move(state.list, from, to),
				editingTitle: false,
			}
		}

		case POST_IT.UPLOAD_LIST_CONTENT:
		case POST_IT.SAVE_LIST: {
			return {
				...state,
				editingTitle: false,
			}
		}

		case POST_IT.EDIT_TITLE: {
			return {
				...state,
				editingTitle: true,
			}
		}

		case POST_IT.DELETE_POST_IT_ACTION: {
			const { uid } = action
			return {
				...state,
				list: state.list.filter(p => p.uid !== uid),
				editingTitle: false,
			}
		}

		case POST_IT.SAVE_EDIT_TITLE: {
			const { uid, text } = action
			return {
				...state,
				list: state.list.map(postIt =>
					postIt.uid === uid ? ({ ...postIt, title: text }) : postIt
				),
				editingTitle: false,
			}
		}

		case POST_IT.CANCEL_EDIT_TITLE: {
			return {
				...state,
				editingTitle: false,
			}
		}

		case POST_IT.CHANGE_COLOR: {
			const { uid, color } = action
			return {
				...state,
				list: state.list.map(postIt =>
					postIt.uid === uid ? ({ ...postIt, color }) : postIt
				),
				editingTitle: false,
			}
		}

		default: {
			if (action.type.startsWith('TASK:')) {
				const { postItUid } = action
				return {
					...state,
					list: state.list.map(postIt =>
						postIt.uid === postItUid ? ({ ...postIt, tasks: taskReducer(postIt.tasks, action) }) : postIt
					),
				}
			}
			return state
		}
	}
}
