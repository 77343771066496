import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const PostItTaskListTitle = ({
    postItTitle,
    editFieldRef,
	editing,
	handleEdit,
	editText,
	handleSubmit,
	handleChange,
	handleKeyDown,
}) => (
    <div className={classNames('post-it-title-wrapper', { editing })}>
        <h5 onClick={handleEdit} className="view">{postItTitle}</h5>
		<input
			type="text"
			ref={editFieldRef}
			className="edit-title"
			value={editText}
			onBlur={handleSubmit}
			onChange={handleChange}
			onKeyDown={handleKeyDown}
		/>
    </div>
)

PostItTaskListTitle.propTypes = {
	postItTitle: PropTypes.string.isRequired,
	editFieldRef: PropTypes.object.isRequired,
	editing: PropTypes.bool.isRequired,
	handleEdit: PropTypes.func.isRequired,
	editText: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	handleKeyDown: PropTypes.func.isRequired,
}

export default PostItTaskListTitle
