import { takeEvery, put, call, fork, take, select } from 'redux-saga/effects'

import { POST_IT } from '~/actions/types'
import { POST_IT_STORAGE } from '~/data/storage-keys'
import * as notification from '~/actions/notifications'
import {
	listSuccess,
	listFailure,
	deletePostItAction,
} from '~/actions/post-it'
import { viewTasksModal, confirmModal } from './modal-creation'
import localforage, { downloadFileFromText, tryParseJson } from '~/utils/storage'

const getItem = localforage.getItem.bind(localforage)
const setItem = localforage.setItem.bind(localforage)

function* onListRequest() {
	try {
		let postItList = yield call(getItem, POST_IT_STORAGE)
		if (!postItList) {
			postItList = []
		}
		yield put(listSuccess(postItList))
	}
	catch (err) {
		const errDescr = err.message
		yield put(listFailure(errDescr))
		yield put(notification.error({
			title: 'Error',
			message: errDescr,
		}))
	}
}

const getPostItList = state => state.postIts

function* onViewTasks() {
	while (true) {
		const { uid } = yield take(POST_IT.VIEW_TASKS)

		const { list } = yield select(getPostItList)
		const postIt = list.find(e => e.uid === uid)

		if (!postIt) {
			continue
		}

		yield call(viewTasksModal, {
			postItUid: uid,
			title: `Tasks: ${postIt.title}`,
		})
	}
}

function* onSaveList() {
	const { list } = yield select(getPostItList)

	yield call(setItem, POST_IT_STORAGE, list)

	yield put(notification.success({
		title: 'Success',
		message: 'Post-It list successfully saved.',
	}))
}

function* onDownloadList() {
	const { list } = yield select(getPostItList)

	yield call(downloadFileFromText, {
		text: JSON.stringify(list),
		filename: 'post-it-export.json',
		type: 'application/json',
	})

	yield put(notification.success({
		title: 'Success',
		message: 'Post-It list successfully exported.',
	}))
}

function* onUploadListContent({ text, mimeType }) {
	let list = undefined

	if (mimeType === 'application/json') {
		list = tryParseJson(text)
	}

	if (list) {
		yield put(listSuccess(list))

		yield put(notification.success({
			title: 'Success',
			message: 'Post-It list successfully imported.',
		}))
		return
	}

	yield call(onUploadListReason, {
		reason: 'Failed to parse imported file!',
	})
}

function* onUploadListReason({ reason }) {
	yield put(notification.success({
		title: 'Error',
		message: reason,
	}))
}

function* onDeletePostIt({ uid }) {
	const { list } = yield select(getPostItList)

	const postIt = list.find(p => p.uid === uid)

	if (!postIt) {
		return
	}

	const tasksToDo = postIt.tasks.filter(t => !t.completed)

	if (tasksToDo.length === 0) {
		yield put(deletePostItAction(uid))
		return
	}

	const confirmed = yield call(confirmModal, {
		title: 'Are you sure you want to delete that post-it?',
		text: 'There are still tasks to do.',
	})

	if (!confirmed) {
		return
	}

	yield put(deletePostItAction(uid))
}

export default function*() {
    yield takeEvery(POST_IT.LIST_REQUEST, onListRequest)
	yield fork(onViewTasks)
    yield takeEvery(POST_IT.SAVE_LIST, onSaveList)
    yield takeEvery(POST_IT.DOWNLOAD_LIST, onDownloadList)
    yield takeEvery(POST_IT.UPLOAD_LIST_CONTENT, onUploadListContent)
    yield takeEvery(POST_IT.UPLOAD_LIST_ERROR, onUploadListReason)
    yield takeEvery(POST_IT.DELETE_POST_IT, onDeletePostIt)
}
