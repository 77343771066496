import React from 'react'
import classNames from 'classnames'
import { sagaModal } from 'redux-saga-modal'
import { withTranslation } from 'react-i18next'

import { ModalWrapper, ModalBody, ModalFooter } from '~/containers/ModalWrapper'

import MODAL_TYPES from '~/data/modal-types'

const getInputClassname = (className) => {
	if (className === null) {
		return undefined
	}
	return classNames('form-control', className)
}

class Modal extends React.PureComponent
{
	state = {}

	onInputChange = (e) => {
		const { target, target: { name } } = e
		const value = target.type === 'checkbox' ? target.checked : target.value
		this.setState({ [name]: value })
	}

	componentDidMount() {
		const { data: { fields } } = this.props

		const defaultState = fields ? fields.reduce((acc, val) => {
			acc[val.name] = val.defaultValue || ''
			return acc
		}, {}) : {}

		if (Object.keys(defaultState).length) {
			this.setState(defaultState)
		}

		if (this.autofocusDOMRef) {
			setImmediate(() => this.autofocusDOMRef.focus())
		}
	}

	setAutofocusDOMRef = (element) => {
		this.autofocusDOMRef = element
	}

	render() {
		const {
			t,
			isOpen,
			submit,
			hide,
			data={},
		} = this.props
		return (
			<ModalWrapper
				isOpen={isOpen}
				hide={hide}
				data={data}
			>
				<ModalBody>
					{ data.text && <p>{data.text}</p> }
					<form>
						{ data.fields?.map(field => {
							return (
								<div className="form-group" key={field.name}>
									<label htmlFor={field.name}>{field.label}</label>
									<input
										id={field.name}
										name={field.name}
										type={field.type || 'text'}
										className={getInputClassname(field.className)}
										placeholder={field.placeholder}
										onChange={this.onInputChange}
										defaultValue={field.defaultValue}
									/>
								</div>
							)
						})}
					</form>
				</ModalBody>
				<ModalFooter>
					<button type="button" className="success" onClick={() => submit(this.state)} ref={this.setAutofocusDOMRef}>{t('modal.actions.continue')}</button>
					<button type="button" className="default" onClick={hide}>{t('modal.actions.cancel')}</button>
				</ModalFooter>
			</ModalWrapper>
		)
	}
}

export default withTranslation()(
	sagaModal({
		name: MODAL_TYPES.PROMPT,
		destroyOnHide: false,
		keepComponentOnHide: true,
	})(Modal)
)
