import shortid from 'shortid'

import { TASK } from '~/actions/types'

export const VisibilityFilters = {
	SHOW_ALL: 'SHOW_ALL',
	SHOW_COMPLETED: 'SHOW_COMPLETED',
	SHOW_ACTIVE: 'SHOW_ACTIVE'
}

export const addTask = (postItUid, text) => ({
	type: TASK.ADD,
	uid: shortid.generate(),
	postItUid,
	text,
})

export const toggleAllTasks = (postItUid, checked) => ({
	type: TASK.TOGGLE_ALL,
	postItUid,
	checked,
})

export const toggleAllTasksAction = (postItUid, checked) => ({
	type: TASK.TOGGLE_ALL_ACTION,
	postItUid,
	checked,
})

export const toggleTask = (postItUid, uid) => ({
	type: TASK.TOGGLE,
	postItUid,
	uid,
})

export const destroyTask = (postItUid, uid) => ({
	type: TASK.DESTROY,
	postItUid,
	uid,
})

export const clearCompletedTasks = (postItUid) => ({
	type: TASK.CLEAR_COMPLETED,
	postItUid,
})

export const editTask = (postItUid, uid) => ({
	type: TASK.EDIT,
	postItUid,
	uid,
})

export const saveTaskEdit = (postItUid, uid, text) => ({
	type: TASK.SAVE_EDIT,
	postItUid,
	uid,
	text,
})

export const cancelTaskEdit = (postItUid) => ({
	type: TASK.CANCEL_EDIT,
	postItUid,
})

export const setVisibilityFilter = (filter) => ({
	type: TASK.SET_VISIBILITY_FILTER,
	filter,
})

export const reorderTask = (postItUid, from, to) => ({
	type: TASK.REORDER_TASK,
	postItUid,
	from,
	to,
})
