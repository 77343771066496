import React from 'react'
import PropTypes from 'prop-types'

const NewPostItButton = ({ onClicked }) => (
	<button className="new-post-it-button" onClick={onClicked}><i className="icon-add" aria-hidden="true"></i></button>
)

NewPostItButton.propTypes = {
	onClicked: PropTypes.func.isRequired,
}

export default NewPostItButton
