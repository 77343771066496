import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { GithubPicker } from 'react-color'
import { sagaModal } from 'redux-saga-modal'

import { ModalWrapper, ModalBody } from '~/containers/ModalWrapper'
import PostItTaskListTitle from '~/containers/PostItTaskListTitle'
import TaskListHeader from '~/containers/TaskListHeader'
import TaskListContent from '~/containers/TaskListContent'
import TaskListFooter from '~/containers/TaskListFooter'
import PostItDeleteButton from '~/containers/PostItDeleteButton'

import MODAL_TYPES from '~/data/modal-types'
import colorPalette, { reversed as reversedColorPalette } from '~/data/color-palette'
import { changePostItColor } from '~/actions/post-it'

class Modal extends React.PureComponent
{
	onColorChanged = (color) => {
		const { changePostItColor, data: { postItUid } } = this.props
		const colorName = reversedColorPalette[color.hex]
		changePostItColor(postItUid, colorName)
	}

	render() {
		const {
			isOpen,
			hide,
			data,
			tasks,
		} = this.props

		const activeTaskCount = tasks.reduce((acc, task) => task.completed ? acc : acc + 1, 0)
		const completedTaskCount = tasks.length - activeTaskCount

		return (
			<ModalWrapper
				isOpen={isOpen}
				hide={hide}
				data={data}
				displayTitle={false}
				large={true}
			>
				<PostItDeleteButton postItUid={data.postItUid} />
				<ModalBody>
					<PostItTaskListTitle postItUid={data.postItUid} />
					<GithubPicker
						width="100%"
						triangle="hide"
						colors={Object.values(colorPalette)}
						onChangeComplete={this.onColorChanged}
					/>
					<TaskListHeader postItUid={data.postItUid} />
					{ (tasks.length > 0) && (
						<TaskListContent
							postItUid={data.postItUid}
							activeTaskCount={activeTaskCount}
							tasks={tasks}
						/>
					)}
					{ (activeTaskCount > 0 || completedTaskCount > 0) && (
						<TaskListFooter
							postItUid={data.postItUid}
							count={activeTaskCount}
							completedCount={completedTaskCount}
						/>
					)}
				</ModalBody>
			</ModalWrapper>
		)
	}
}

Modal.defaultProps = {
	data: {},
	tasks: [],
}

const mapStateToProps = (state, ownProps) => {
	const { postItUid } = (ownProps.data || {})
	if (!postItUid) {
		return {}
	}
	const { postIts } = state
	const postIt = postIts.list.find(p => p.uid === postItUid)
	return {
		tasks: postIt ? postIt.tasks : [],
	}
}

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		changePostItColor,
	}, dispatch)
)

export default sagaModal({
	name: MODAL_TYPES.VIEW_TASKS,
	destroyOnHide: false,
	keepComponentOnHide: true,
})(connect(
	mapStateToProps,
	mapDispatchToProps,
)(Modal))
