import React from 'react'

import { useAutofocusElement } from '~/utils/hooks'

const TaskListHeader = ({
	newTask,
	handleNewTaskKeyDown,
	handleChange,
}) => {
	const autofocusDOMRef = useAutofocusElement()
	return (
		<header>
			<input
				className="new-task"
				type="text"
				ref={autofocusDOMRef}
				value={newTask}
				onKeyDown={handleNewTaskKeyDown}
				onChange={handleChange}
				placeholder="What needs to be done?"
			/>
		</header>
	)
}

export default TaskListHeader
