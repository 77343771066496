import { NOTIFICATIONS } from '~/actions/types'

export default (state = [], action) => {
	switch (action.type) {
		case NOTIFICATIONS.SHOW: {
			const { type, ...rest } = action
			return [
				...state,
				{ ...rest, uid: action.uid }
			]
		}

		case NOTIFICATIONS.HIDE: {
			return state.filter(notification => {
				return notification.uid !== action.uid
			})
		}

		case NOTIFICATIONS.REMOVE_ALL: {
			return []
		}

		default:
			return state
	}
}
