import React from 'react'
import PropTypes from 'prop-types'
import Dragula from 'react-dragula'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import TaskListContentComponent from '~/components/TaskListContent'

import {
	VisibilityFilters,
	toggleAllTasks,
	toggleTask,
	destroyTask,
	editTask,
	saveTaskEdit,
	cancelTaskEdit,
	reorderTask,
} from '~/actions/tasks'

const getVisibleTasks = (tasks, filter) => {
	switch (filter) {
		case VisibilityFilters.SHOW_ALL:
			return tasks
		case VisibilityFilters.SHOW_ACTIVE:
			return tasks.filter(t => !t.completed)
		case VisibilityFilters.SHOW_COMPLETED:
			return tasks.filter(t => t.completed)
		default:
			throw new Error(`Unknown filter: ${filter}`)
	}
}

class TaskListContent extends React.PureComponent
{
	toggleAll = (event) => {
		const checked = event.target.checked
		const { toggleAllTasks, postItUid } = this.props
		toggleAllTasks(postItUid, checked)
	}

	toggle = (task) => {
		const { toggleTask, postItUid } = this.props
		toggleTask(postItUid, task.uid)
	}

	destroy = (task) => {
		const { destroyTask, postItUid } = this.props
		destroyTask(postItUid, task.uid)
	}

	edit = (task) => {
		const { editTask, postItUid } = this.props
		editTask(postItUid, task.uid)
	}

	save = (task, text) => {
		const { saveTaskEdit, postItUid } = this.props
		saveTaskEdit(postItUid, task.uid, text)
	}

	cancel = () => {
		const { cancelTaskEdit, postItUid } = this.props
		cancelTaskEdit(postItUid)
	}

	dragulaDecorator = (componentBackingInstance) => {
		const { reorderTask, postItUid } = this.props
		if (componentBackingInstance) {
			const options = {}
			const drake = Dragula([componentBackingInstance], options)
			let dragIndex = -1
			drake.on('drag', (el) => {
				dragIndex = Array.from(el.parentNode.children).indexOf(el)
			})
			drake.on('drop', (el) => {
				const dropIndex = Array.from(el.parentNode.children).indexOf(el)
				if (dragIndex > -1 && dropIndex > -1) {
					reorderTask(postItUid, dragIndex, dropIndex)
				}
			})
		}
	}

	render() {
		const {
			activeTaskCount,
			tasks,
			editingTask,
			filter,
		} = this.props
		return (
			<TaskListContentComponent
				toggleAll={this.toggleAll}
				activeTaskCount={activeTaskCount}
				tasks={getVisibleTasks(tasks, filter)}
				editingTask={editingTask}
				onToggle={this.toggle}
				onDestroy={this.destroy}
				onEdit={this.edit}
				onSave={this.save}
				onCancel={this.cancel}
				dragulaDecorator={this.dragulaDecorator}
			/>
		)
	}
}

TaskListContent.propTypes = {
	postItUid: PropTypes.string.isRequired,
	activeTaskCount: PropTypes.number.isRequired,
	tasks: PropTypes.array.isRequired,
}

const mapStateToProps = ({ tasks }) => ({
	editingTask: tasks.editing,
	filter: tasks.filter,
})

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		toggleAllTasks,
		toggleTask,
		destroyTask,
		editTask,
		saveTaskEdit,
		cancelTaskEdit,
		reorderTask,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(TaskListContent)
