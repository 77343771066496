import React from 'react'
import PropTypes from 'prop-types'
import { ReactReduxContext } from 'react-redux'
import ReactNotification from 'react-notification-system'

import * as actions from '~/actions/notifications'

const styles = {
	Containers: {
		DefaultStyle: {
			position: 'fixed',
			zIndex: 8000,
		},
		tc: {
			transform: 'translateX(-50%)',
			top: 20,
			left: '50%',
		},
		bc: {
			transform: 'translateX(-50%)',
			marginBottom: -20,
			bottom: 20,
			left: '50%',
		},
		tl: {
			left: 20,
			top: 20,
		},
		tr: {
			right: 20,
			top: 20,
		},
		bl: {
			left: 20,
			bottom: 20,
			marginBottom: -20,
		},
		br: {
			bottom: 20,
			// right: 20,
			marginBottom: -20,
		},
	},
	NotificationItem: {
		DefaultStyle: {
			padding: '8px 15px',
			display: 'inline-block',
			width: 275,
			marginBottom: 15,
			boxShadow: '1px 3px 4px rgba(0, 0, 0, .2)',
			borderRadius: 4,
			cursor: 'pointer',
			borderTop: 'none',
			overflow: 'hidden',
		},
		/*default: {
			backgroundColor: '#007bff',
			borderLeft: '8px solid #0056b3',
		},*/
		success: {
			backgroundColor: '#28a745',
			borderLeft: '8px solid #19692c',
		},
		error: {
			backgroundColor: '#dc3545',
			borderLeft: '8px solid #a71d2a',
		},
		warning: {
			backgroundColor: '#eab000',
			borderLeft: '8px solid #9e7600',
		},
		info: {
			backgroundColor: '#17a2b8',
			borderLeft: '8px solid #0f6674',
		},
		/*awesome: {
			backgroundColor: '#685dc3',
			borderLeft: '8px solid #44399a',
		},*/
	},
	Title: {
		DefaultStyle: {
			fontWeight: 700,
			fontSize: 14,
			marginTop: 5,
			marginBottom: 5,
			color: '#fff',
			fontFamily: 'Arial, Helvetica, sans-serif',
		},
	},
	MessageWrapper: {
		DefaultStyle: {
			maxWidth: 'calc(100% - 15px)',
			fontSize: 14,
			lineHeight: '150%',
			wordWrap: 'break-word',
			marginBottom: 0,
			marginTop: 0,
			color: '#fff',
			fontFamily: 'Arial, Helvetica, sans-serif',
		}
	},
	Dismiss: {
		DefaultStyle: {
			fontSize: 18,
			position: 'absolute',
			right: 6,
			top: 5,
			color: '#fff',
			fontFamily: 'Arial, Helvetica, sans-serif',
			backgroundColor: 'transparent',
		},
	},
}

class Notifications extends React.Component
{
	notificationDOMRef = React.createRef()

	system = () => {
		return this.notificationDOMRef.current
	}

	componentDidUpdate(prevProps) {
		const { notifications, store } = this.props
		const notificationIds = notifications.map(notif => notif.uid)
		const systemNotifications = this.system().state.notifications || []

		if (notifications.length > 0) {
			// Get all active notifications from react-notifications-component
			/// and remove all where uid is not found in the reducer
			(systemNotifications).forEach(notification => {
				if (notificationIds.indexOf(notification.uid) < 0) {
					this.system().removeNotification(notification.uid)
				}
			})

			notifications.forEach(notification => {
				notification.type = notification.level
				this.system().addNotification({
					...notification,
					onRemove: () => {
						store.dispatch(actions.hide(notification.uid))
						notification.onRemove && notification.onRemove()
					}
				})
			})
		}

		if ((prevProps.notifications !== notifications) && notifications.length === 0) {
			this.system().clearNotifications()
		}
	}

	shouldComponentUpdate(nextProps) {
		return this.props !== nextProps
	}

	render() {
		const { notifications, store, ...rest } = this.props
		return (
			<ReactNotification ref={this.notificationDOMRef} { ...rest } style={styles} />
		)
	}
}

Notifications.propTypes = {
	notifications: PropTypes.array,
	store: PropTypes.shape({
		dispatch: PropTypes.func.isRequired,
	}).isRequired,
}

const NotificationsWithContext = props => {
	const Context = props.context || ReactReduxContext
	if (Context == null) {
		throw new Error('Please upgrade to react-redux v6')
	}

	return (
		<Context.Consumer>
			{(otherProps) => {
				const { store } = otherProps
				return <Notifications store={store} {...props} />
			}}
		</Context.Consumer>
	)
}

NotificationsWithContext.propTypes = {
	context: PropTypes.object,
}

export default NotificationsWithContext
