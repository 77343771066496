import { objectFlip } from '~/utils/object'

const defaultOrder = {
	'turquoise':	'#1abc9c',
	'emerald':		'#2ecc71',
	'peter-river':	'#3498db',
	'amethyst':		'#9b59b6',
	'web-asphalt':	'#34495e',
	'green-sea':	'#16a085',
	'nephritis':	'#27ae60',
	'belize-hole':	'#2980b9',
	'wisteria':		'#8e44ad',
	'gray-sea':		'#2c3e50',
	'sun-flower':	'#f1c40f',
	'carrot':		'#e67e22',
	'lizarin':		'#e74c3c',
	'clouds':		'#ecf0f1',
	'concrete':		'#95a5a6',
	'orange':		'#f39c12',
	'pumpkin':		'#d35400',
	'pomegranate':	'#c0392b',
	'silver':		'#bdc3c7',
	'asbestos':		'#7f8c8d',
}

export default defaultOrder

export const reversed = objectFlip(defaultOrder)
