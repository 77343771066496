import { takeEvery, put, call } from 'redux-saga/effects'

import { TASK } from '~/actions/types'
import { confirmModal } from './modal-creation'
import { toggleAllTasksAction } from '~/actions/tasks'

function* onToggleAllTasks({ postItUid, checked }) {
	const confirmed = yield call(confirmModal, {
		title: 'Are you sure you want to toggle all taks?',
		text: 'This operation cannot be undone.',
	})

	if (!confirmed) {
		return
	}

	yield put(toggleAllTasksAction(postItUid, checked))
}

export default function*() {
    yield takeEvery(TASK.TOGGLE_ALL, onToggleAllTasks)
}
