import React from 'react'
import classNames from 'classnames'

const PostItCard = ({
	postIt,
	onCardClicked,
}) => {
	const { uid, title, color, tasks } = postIt

	const taskCount = tasks.length
	const remainingTaskCount = tasks.reduce((acc, task) => !task.completed ? acc : acc + 1, 0)

	return (
		<li className="post-it-card">
			<div className="card-wrapper" onClick={() => onCardClicked(uid)}>
				<div className={classNames('card', `color-${color}`)}>
					<i className="pin"></i>
					<p className="title">{title}</p>
					<p className="tasks">{remainingTaskCount}/{taskCount}</p>
				</div>
			</div>
		</li>
	)
}

export default PostItCard
