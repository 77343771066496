import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import PostItTaskListTitleComponent from '~/components/PostItTaskListTitle'

import { ENTER_KEY, ESCAPE_KEY } from '~/data'

import {
	editPostItTitle,
	savePostItTitleEdit,
	cancelPostItTitleEdit,
} from '~/actions/post-it'

class PostItTaskListTitle extends React.Component
{
	editFieldRef = React.createRef()

	constructor(props) {
		super(props)
		this.state = {
			editText: props.postItTitle,
		}
	}

	handleSubmit = () => {
		const val = this.state.editText.trim()
		const { savePostItTitleEdit, postItUid } = this.props
		if (val) {
			savePostItTitleEdit(postItUid, val)
			this.setState({ editText: val })
		}
	}

	handleKeyDown = (event) => {
		if (event.which === ESCAPE_KEY) {
			const { cancelPostItTitleEdit, postItTitle } = this.props
			this.setState({ editText: postItTitle })
			cancelPostItTitleEdit(event)
		}
		else if (event.which === ENTER_KEY) {
			this.handleSubmit(event)
		}
	}

	handleEdit = () => {
		const { editPostItTitle, postItTitle } = this.props
		editPostItTitle()
		this.setState({ editText: postItTitle })
	}

	handleChange = (event) => {
		if (this.props.editing) {
			this.setState({ editText: event.target.value })
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return (
			nextProps.editing !== this.props.editing ||
			nextState.editText !== this.state.editText
		)
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.editing && this.props.editing) {
			const node = this.editFieldRef.current
			node.focus()
			node.setSelectionRange(node.value.length, node.value.length)
		}
	}

	render() {
		const {
			postItTitle,
			editing,
		} = this.props
		const { editText } = this.state
		return (
			<PostItTaskListTitleComponent
				postItTitle={postItTitle}
				editFieldRef={this.editFieldRef}
				editing={editing}
				handleEdit={this.handleEdit}
				editText={editText}
				handleSubmit={this.handleSubmit}
				handleChange={this.handleChange}
				handleKeyDown={this.handleKeyDown}
			/>
		)
	}
}

PostItTaskListTitle.propTypes = {
	postItUid: PropTypes.string.isRequired,
}

const mapStateToProps = ({ postIts }, ownProps) => {
	const postIt = postIts.list.find(p => p.uid === ownProps.postItUid)
	if (postIt) {
		return { 
			editing: postIts.editingTitle,
			postItTitle: postIt.title,
		}
	}
	return {
		editing: postIts.editingTitle,
		postItTitle: '',
	}
}

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		editPostItTitle,
		savePostItTitleEdit,
		cancelPostItTitleEdit,
	}, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(PostItTaskListTitle)
