import ModalAlert from '~/components/ModalAlert'
import ModalConfirm from '~/components/ModalConfirm'
import ModalPrompt from '~/components/ModalPrompt'
import ModalDemo from '~/components/ModalDemo'
import ModalViewTasks from '~/containers/ModalViewTasks'

export default [
	ModalAlert,
	ModalConfirm,
	ModalPrompt,
	ModalDemo,
	ModalViewTasks,
]
