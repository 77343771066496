import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactModal from 'react-modal'

export const ModalBody = function({ children }) {
	return (
		<div className="modal-body">
			{ children }
		</div>
	)
}

export const ModalFooter = function({ children }) {
	return (
		<div className="modal-footer">
			{ children }
		</div>
	)
}

export const ModalWrapper = function({
	children,
	isOpen,
	hide,
	className,
	data,
	displayTitle,
	large
}) {
	if (!data.type) {
		return null
	}
	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={hide}
			contentLabel={data.title}
			ariaHideApp={false}
			overlayClassName="modal-overlay"
			bodyOpenClassName="modal-open"
			className={classNames('modal-container', data.severity, { 'modal-large': large }, className, `modal-type-${data.type}`)}
		>
			<div className="modal-content">
				<div className="modal-header">
					{ displayTitle && <h5 className="modal-title">{data.title}</h5> }
					<button type="button" className="close" aria-label="Close" onClick={hide}>
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				{ children }
			</div>
		</ReactModal>
	)
}

ModalWrapper.defaultProps = {
	data: {},
	displayTitle: true,
	large: false,
}

ModalWrapper.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	hide: PropTypes.func.isRequired,
	className: PropTypes.string,
	data: PropTypes.object.isRequired,
	displayTitle: PropTypes.bool,
	large: PropTypes.bool,
}
