import i18n from 'i18next'
import TimeAgo from 'react-timeago'
import { initReactI18next } from 'react-i18next'

import locales, { defaultLanguage, saveLocale, loadLocale } from '~/locales'

i18n.use(initReactI18next) // passes i18n down to react-i18next

i18n.on('languageChanged', (lang) => {
	if (lang in locales) {
		TimeAgo.defaultProps = {
			...TimeAgo.defaultProps,
			formatter: locales[lang].timeagoFormatter,
		}
		saveLocale(lang)
	}
})

i18n.init({
	// debug: true,
	resources: locales,
	lng: defaultLanguage,
	fallbackLng: defaultLanguage,
}, () => {
	loadLocale(lang => {
		i18n.changeLanguage(lang)
	})
})

export default i18n
