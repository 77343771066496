import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import NavbarComponent from '~/components/Navbar'

import {
	saveList,
	downloadList,
	uploadListContent,
	uploadListError,
} from '~/actions/post-it'

class Navbar extends React.PureComponent
{
	onSaveButtonClicked = () => {
		const { saveList } = this.props
		saveList()
	}

	onDownloadButtonClicked = () => {
		const { downloadList } = this.props
		downloadList()
	}

	onInputFileChanged = (e) => {
		const { uploadListContent, uploadListError } = this.props
		if (window.File && window.FileReader && window.FileList && window.Blob) {
			const file = e.target.files[0]
			const reader = new FileReader()
			reader.onload = function(event) {
				uploadListContent(event.target.result, file.type)
			}
			reader.readAsText(file)
		}
		else {
			uploadListError(`Your browser doens't support HTML5 File API`)
		}
	}

	render() {
		return (
			<NavbarComponent
				onSaveButtonClicked={this.onSaveButtonClicked}
				onDownloadButtonClicked={this.onDownloadButtonClicked}
				onInputFileChanged={this.onInputFileChanged}
			/>
		)
	}
}

const mapDispatchToProps = (dispatch) => (
	bindActionCreators({
		saveList,
		downloadList,
		uploadListContent,
		uploadListError,
	}, dispatch)
)

export default connect(null, mapDispatchToProps)(Navbar)
