import { combineReducers } from 'redux'
import { reducer as modalReducer } from 'redux-saga-modal'

import notifications from './notifications'
import postIts from './post-it'
import tasks from './tasks'
import app from './app'

export default () => combineReducers({
	notifications,
	modals: modalReducer,
	postIts,
	tasks,
	app,
})
